import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import HeroVideo from 'sections/hero/HeroVideo.js'
import AboutOne from 'sections/about/AboutOne.js'
import ServicesOne from 'sections/services/ServicesOne.js'
import PortfolioOne from 'sections/portfolio/PortfolioOne.js'
import TestimonialsOne from 'sections/testimonials/TestimonialsOne.js'
import TeamOne from 'sections/team/TeamOne.js'
import ClientsOne from 'sections/clients/ClientsOne.js'
import ContactCreative from 'sections/contact/ContactCreative.js'
import OgImage from '../../static/img/inpay_logo.png'

class Index extends React.Component {

  render() {
    
    const { site } = this.props.data
    
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
            <meta name="image" content={OgImage}/>
            <meta property="og:image" content={OgImage}/>
            {/*<meta property="og:image" content={site.meta.icon}/>*/}
            <meta property="og:type"  content='website'/>
            <meta name="twitter:image"    property="twitter:image" content={OgImage}/>
            <meta name="twitter:card"     property="twitter:card" content='summary'/>
            <meta name="twitter:creator"  property="twitter:creator" content={site.meta.author}/>
            <meta property="og:url" content="" />
            <meta property="fb:app_id" content=""/>
            <meta name="author" content={site.meta.author}/>
            <meta property="og:title" content={site.meta.title}/>
            <meta name='date' content=""/>
            <meta name='robots' content='index,follow'/>
        </Helmet>
        <Layout
          isHome={true}
          sections={['home', 'about', 'how we do it', 'clients', 'investor relations', 'contact']}
          // sections={['home', 'about', 'how we do it', 'portfolio', 'testimonials', 'team', 'clients', 'contact']}
        >
          <HeroVideo />
          <AboutOne />
          <ServicesOne />
          {/*<PortfolioOne />*/}
          {/*<TestimonialsOne />*/}
          {/*<TeamOne />*/}
          <ClientsOne />
          <ContactCreative />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        icon
      }
    }
  }
`