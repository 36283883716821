import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import ContactCreativeForm from './parts/ContactCreativeForm.js'

class ContactCreative extends React.Component {


    render() {

        const Section = styled.section`
            position: relative;
            overflow: hidden;
            //background-color: #000;
                        background: rgb(13,31,64);
            background: linear-gradient( rgba(13,31,64,1) 0%, rgba(7,43,110,1) 52%, rgba(13,31,64,1) 100%);
            background: -webkit-linear-gradient( rgba(13,31,64,1) 0%, rgba(7,43,110,1) 52%, rgba(13,31,64,1) 100%);
            padding: 100px 0;
        `

        const FormRow = styled(Row)`
           background-color: #ffffff;
        `

        const ContactCol = styled(Col)`
            min-height: 600px;
            max-height: 600px;
            padding: 0;
            display: flex;
            align-items: center;
        `

        const Map = styled.iframe`
            border: none;
            height: 100%;
            width: 100%;
        `

        const IconRow = styled(Row)`
            margin-top: 150px;
        `

        const IconCol = styled(Col)`
            @media (max-width: 767px) {
              margin-bottom: 140px;
            }
        `

        const IconContainer = styled.div`
            width: 150px;
            height: 150px;
            margin: auto;
            padding: 35px;
            text-align: center;
            position: relative;
            bottom: 75px;
            background-color: #FFA500;
            border-radius: 150px;
            transition: .5s;
        `
        
        const InfoPart = styled.div`
            min-height: 250px;
            background-color: #122d5a;
            border: 2px solid #FFA500;
            //border: 2px solid #444444;
            &:hover ${IconContainer} {
              background-color: #cd8600;
            }
        `
        const Icon = styled.img`
            height: 70px;
            width: 70px;
            object-fit: contain;
        `

        const InfoTitle = styled.h4`
            font-size: 35px;
            color: #fff;
            font-family: Teko;
            text-align: center;
        `

        const Info = styled.div`
            position: relative;
            bottom: 30px;
        `

        const InfoLinkContainer = styled.div`
            text-align: center;
        `

        const InfoLink = styled.a`
            color: #FFA500;
            transition: .5s;
            &:hover {
              color: #fff;
              text-decoration: none;
            }
        `

        return(
            <Section id="contact">
                <Container>
                  {/*<AnimationContainer animation="fadeIn">*/}
                  {/*  <FormRow>*/}
                  {/*    <ContactCol md={6}>*/}
                  {/*        <ContactCreativeForm />*/}
                  {/*    </ContactCol>*/}
                  {/*    <ContactCol md={6}>*/}
                  {/*      <Map*/}
                  {/*          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.3256199268235!2d20.999531315755135!3d52.237468979761694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecdc416bc9523%3A0x9cf0948a7ed21db9!2sGrzybowska%204!5e0!3m2!1spl!2spl!4v1599333473593!5m2!1spl!2spl"*/}
                  {/*        title="map" />*/}
                  {/*    </ContactCol>*/}
                  {/*  </FormRow>*/}
                  {/*</AnimationContainer>*/}
                  <IconRow>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={500}>
                          <InfoPart>
                            <IconContainer>
                                <Icon src="/bottomInfoIcons/mail.svg" alt="email" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                Email
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink href="mailto:info@inpay.pl">
                                    info@inpay.pl
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={1000}>
                          <InfoPart>
                            <IconContainer>
                              <Icon src="/bottomInfoIcons/mobile.svg" alt="phone" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                Phone
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink href="tel:+(48)222302621">
                                {/*<InfoLink href="tel:+(123)123-1234">*/}
                                    +48 22 230 2621
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={1500}>
                          <InfoPart>
                            <IconContainer>
                              <Icon src="/bottomInfoIcons/place.svg" alt="map" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                Address
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink target="_blank" href="https://www.google.com/maps/place/Sienna+86/">
                                    Sienna 86/47 00-815 Warszawa
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                  </IconRow>
                </Container>
            </Section>
        )
    }

}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      emailIcon: file(relativePath: {eq: "icons/email2.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            src
          }
        }
      }
      mapIcon: file(relativePath: {eq: "icons/map.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            src
          }
        }
      }
      phoneIcon: file(relativePath: {eq: "icons/phone.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            src
          }
        }
      }
    }
    `}
    render={({ 
      emailIcon, 
      mapIcon, 
      phoneIcon}) => <ContactCreative  
      emailIcon={emailIcon} 
      mapIcon={mapIcon} 
      phoneIcon={phoneIcon}
      {...props} />}
  />
)