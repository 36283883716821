import React from 'react'
import styled from 'styled-components'
import  {Row, Col, Container} from "react-bootstrap";

class Footer extends React.Component {

    render() {
        const FooterMain = styled.div`
            background-color: #081024;
        `

        const FooterParagraph = styled.p`
            color: white;
            font-size: 20px;
            padding: 30px 0;
            font-family: Teko;
            &:after{
             @media (min-width: 768px) {
              content : "";
                position: absolute;
                right    : 0;
                top  : 15%;
                height  : 70%;
                width   : 2px;
                z-index: 2;
              border-right: 2px solid #FFA500;
            }
              @media (min-width: 992px) {
              content : "";
                position: absolute;
                right    : 0;
                top  : 25%;
                height  : 50%;
                width   : 2px;
                z-index: 2;
              border-right: 2px solid #FFA500;
            }
            }
        `


        const FooterParagraphRight = styled.p`
            color: white;
            font-size: 20px;
            padding: 30px 0;
            font-family: Teko;
        `

        return (
            <FooterMain>
                <Container>
                    <Row>
                        <Col md={8}>
                            <FooterParagraph>InPay S.A., National Court Register: 0000512707 District Court Warsaw, XII
                                Economic Department<br/>
                                Capital: 100 000 PLN (fully paid)</FooterParagraph>
                        </Col>
                        <Col md={4}>
                            <FooterParagraphRight>VAT: 7010430929, REGON: 147302566, Sienna 86/47, 00-815 Warsaw, Poland</FooterParagraphRight>
                        </Col>
                    </Row>
                </Container>
            </FooterMain>
        )
    }
}

export default Footer