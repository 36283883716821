import React, {useEffect, useState} from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import {StaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'

function ClientsOne (props) {
    const size = useWindowSize();

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

        const clientList = props.clients;

        return (
            <Section id="clients">
                <Container>
                    <Row>
                        <LeftCol md={6}>
                            <Heading>
                                <Color>Clients </Color>
                                we worked with
                            </Heading>
                        </LeftCol>
                        <Col md={6}>
                            <Row>
                                {clients()}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Section>
        )


    function clients() {
        const linkTable = {
            "001.png": "https://zdrovit.pl/",
            "002.png": "https://isel.tech/",
            "003.png": "https://fiberpay.pl/",
            "004.png": "https://swap.ly/",
            "005.png": "https://beesfund.com/",
            "006.png": "https://ogen.pl/",
            "007.png": "http://musicpoint.pl/",
            "008.png": "https://panoramafirm.pl/mazowieckie,,warszawa,bemowo,rozali%C5%84ska,5/purinvest_sp._z_o.o.-zrpsem_ch.html"
        }

        return clientList.map((value, index) => {
            const longSrc = value.node.childImageSharp.fluid.src
            const imageNameSrc = value.node.childImageSharp.fluid.originalName
            const link = linkTable[imageNameSrc]
            console.log(longSrc);
            // const scaleImageDown = (size.width > 749 && size.width < 1000)
            // const imageSize = scaleImageDown ? 70 : 100

            return (
                <ClientCol md={3} key={index} >
                    <AnimationContainer animation="fadeIn slower" delay={index * 200}>
                        <a href={link} target="_blank">
                            <Client
                                src={longSrc}
                                alt="Client"
                            />
                        </a>
                    </AnimationContainer>
                </ClientCol>
            )
        })
    }
}

const Client = styled.img`
            height: 100px;
            width: 100px;
            @media (min-width: 750px) and ( max-width: 999px) {
              height: 70px;
              width: 70px;
            }
        `

const ClientCol = styled(Col)`
            text-align: center;
            padding: 20px 0;
            border-color: #efefef;
            border-left: none;
            border-top: none;
            transition: .1s;
            &:hover {
              transform: scale(1.1);
              background-color: #FFA500;
              z-index: 5;
              border-radius: 10px;
            }
            @media (max-width: 500px) {
              border: none !important;
            }
        `

const Section = styled.section`
            position: relative;
            overflow: hidden;
            //background-color: #050505;
                        background: rgb(13,31,64);
            background: linear-gradient( rgba(13,31,64,1) 0%, rgba(7,43,110,1) 52%, rgba(13,31,64,1) 100%);
            background: -webkit-linear-gradient( rgba(13,31,64,1) 0%, rgba(7,43,110,1) 52%, rgba(13,31,64,1) 100%);
            background-size: cover;
            padding: 100px 0;
            .heading {
                width: 100%;
            }          
        `

const Heading = styled.h1`
            font-size: 110px;
            line-height: 100px;
            font-family: Teko;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            @media (min-width:768px) and (max-width:1399px) {
                font-size: 70px;
                line-height: 70px;
            }
            @media (max-width:767px) {
                font-size: 40px;
                line-height: 40px;
                text-align: center;
            }
        `
const Color = styled.span`
          color:  #FFA500;
          font-size: 120px;
          line-height: 80px;
          font-family: Teko;
          text-transform: uppercase;
          letter-spacing: 2px;
          @media (min-width:768px) and (max-width:1399px) {
              font-size: 70px;
              line-height: 70px;
          }
          @media (max-width:767px) {
              font-size: 40px;
              line-height: 40px;
              //color: #0050ff;
          }
      `

const LeftCol = styled(Col)`
          display: flex;
          align-items: center;
      `

export default props => (
    <StaticQuery
        query={graphql`
      query {
        clients: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "clients"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 500
                ,duotone: {
                    highlight: "#FFFFFF",
                    shadow: "#0050ff",
                     }) 
                     {
                  src,
                  originalName
                }
              }
            }
          }
        }
      }      
      `}
        render={({clients}) => <ClientsOne clients={clients.edges} {...props} />}
    />
)